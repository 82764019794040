import { DeviceInterface } from './model';

const devices: DeviceInterface[] = [
  {
    name: 'apple',
    link: '',
    width: ['18px', '36px'],
    height: ['18px', '36px'],
    margin: ['0 0 0 3px', '0 0 0 -3px'],
  },
  {
    name: 'google',
    link: '',
    width: ['16px', '31px'],
    height: ['18px', '36px'],
    margin: ['0 0 0 3px', '0 0 0 0'],
  },
  {
    name: 'roku',
    link: '',
    width: ['60px', '122px'],
    height: ['18px', '36px'],
    margin: ['0 8px 0 0', '0 16px 0 0'],
  },
  {
    name: 'appletv',
    link: '',
    width: ['38px', '76px'],
    height: ['18px', '36px'],
    margin: ['0 15px 0 0', '0 27px 0 0'],
  },
  {
    name: 'firetv',
    link: '',
    width: ['52px', '106px'],
    height: ['18px', '36px'],
    margin: ['0 5px 0 0', '0 24px 0 0'],
  },
  {
    name: 'chromecast',
    link: '',
    width: ['24px', '40px', '300px'],
    height: ['18px', '36px'],
    margin: ['0 4px 0 0', '0 6px 0 0'],
  },
  {
    name: 'samsungtv',
    link: '',
    width: ['48px', '95px'],
    height: ['18px', '36px'],
    margin: ['0 4px 0 0', '0 6px 0 0'],
  },
];

export default devices;
