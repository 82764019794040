import React from 'react';
import { DeviceIcon } from 'components/Devices/model';

export const FireTvIcon = ({ color }: DeviceIcon) => (
  <svg
    width="106"
    height="36"
    viewBox="0 0 106 36"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.25708 15.3453H0V11.7837H3.25708V7.91762C3.25708 2.15815 6.71276 0 10.5259 0C11.7837 0 13.3328 0.251563 14.5907 0.847371L13.6771 4.40897C12.7768 4.05149 11.8764 3.85289 10.9761 3.85289C8.72527 3.85289 7.66605 5.25634 7.66605 7.75874V11.7705H13.73L12.5782 15.3321H7.66605V35.4836H3.25708V15.3453Z" />
    <path d="M17.146 4.06473C17.146 2.51563 18.2979 1.25781 19.9529 1.25781C21.6609 1.25781 22.8657 2.46267 22.8657 4.06473C22.8657 5.66679 21.6079 6.9246 19.9529 6.9246C18.2979 6.91136 17.146 5.66679 17.146 4.06473Z" />
    <path d="M22.2038 11.7838H17.7948V35.4969H22.2038V11.7838Z" />
    <path d="M26.52 11.7838H30.2273L30.7304 14.6966C33.2328 12.0353 35.2453 11.188 37.4034 11.188C38.4097 11.188 39.5086 11.4395 40.2103 11.8897L38.648 15.7955C37.8933 15.3983 37.1386 15.1997 36.3972 15.1997C34.6892 15.1997 33.1401 16.1001 30.929 18.2582V35.5101H26.52V11.7838Z" />
    <path d="M39.0585 23.4616C39.0585 16.2457 43.0173 11.1747 49.0813 11.1747C55.3042 11.1747 58.9055 15.9411 58.9055 23.5145V24.6134H43.5602C43.6661 29.0754 46.3671 32.3854 51.0806 32.3854C53.3314 32.3854 55.291 31.6837 56.946 30.7834L58.3494 33.8418C56.1383 35.2453 53.2387 36 50.5245 36C42.6599 36 39.0585 30.2273 39.0585 23.4616ZM54.3509 21.3034C54.245 17.9934 52.6429 14.6833 49.0813 14.6833C45.6654 14.6833 43.6131 17.6888 43.5602 21.3034H54.3509Z" />
    <path d="M105.868 4.07794C102.558 14.7363 99.3277 25.143 96.0706 35.6292C94.8525 35.6292 93.7006 35.6292 92.2972 35.6292C89.0533 25.2357 85.783 14.7363 82.4597 4.09118C83.6116 4.09118 84.5252 4.09118 85.5976 4.09118C88.3781 13.121 91.1717 22.2037 94.1773 31.975C97.196 22.2699 100.029 13.2004 102.863 4.07794C103.803 4.07794 104.716 4.07794 105.868 4.07794Z" />
    <path d="M80.5663 4.10446C80.1956 5.05775 79.8911 5.83892 79.5336 6.75249C76.025 6.75249 72.5693 6.75249 68.862 6.75249C68.862 16.4311 68.862 25.9507 68.862 35.5763C67.8426 35.5763 67.0614 35.5763 66.0948 35.5763C66.0948 26.0831 66.0948 16.6164 66.0948 6.89814C62.4141 6.89814 58.9054 6.89814 55.3174 6.89814C54.9599 5.8654 54.6553 5.01803 54.3376 4.10446C63.1291 4.10446 71.7219 4.10446 80.5663 4.10446Z" />
  </svg>
);
