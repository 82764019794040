import { IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { Slider } from 'components/Common/Slider/Slider';
import { GatsbyImage } from 'components/Common/GatsbyImage';
import { CircleArrows } from 'components/Common/Slider/Slider.styles';
import { StandardRichText } from 'components/StandardRichText/StandardRichText';

import {
  LinkObjectFragment,
  SanityStandardRichTextWithInsertsFragment,
} from 'graphql-types';

import {
  CtaLink,
  Body,
  BodyContent,
  ProgramSlider,
  ProgramCarouselWrapper,
  ProgramCarouselContainer,
} from './ProgramCarousel.styles';

export type ProgramCarouselItem = {
  ctaLink?: string; // deprecated
  ctaLinkObject?: LinkObjectFragment;
  ctaText?: string;
  ctaColor?: string;
  ctaArrowColor?: string;
  image?: IGatsbyImageData;
  content?: SanityStandardRichTextWithInsertsFragment;
  backgroundColor?: string;
};
export const ProgramCarousel: React.FC<{
  backgroundColor: string;
  roundedCorners: boolean;
  footNote?: SanityStandardRichTextWithInsertsFragment;
  items: ProgramCarouselItem[];
}> = ({ items, roundedCorners, backgroundColor, footNote }) => (
  <ProgramCarouselWrapper backgroundColor={backgroundColor}>
    <ProgramCarouselContainer>
      <CircleArrows>
        <Slider
          footNote={footNote}
          options={{
            slidesPerView: 1.5,
            mode: 'snap',
            spacing: 15,
            centered: true,
            breakpoints: {
              '(min-width: 52em)': {
                centered: false,
                slidesPerView: 4,
              },
            },
          }}
        >
          {items.map(
            (
              {
                ctaText,
                ctaLink,
                ctaColor,
                ctaArrowColor,
                content,
                image,
                backgroundColor,
              },
              index
            ) => (
              <ProgramSlider
                key={index}
                href={ctaLink}
                roundedCorners={roundedCorners}
                backgroundColor={backgroundColor}
              >
                <GatsbyImage image={image} alt="" />
                <Body color={content?.desktopColor?.hex || 'inherit'}>
                  {content && (
                    <BodyContent>
                      <StandardRichText fields={content} />
                    </BodyContent>
                  )}
                  {ctaText && ctaLink && (
                    <div>
                      <CtaLink
                        href={ctaLink}
                        color={ctaColor}
                        backgroundColor={ctaArrowColor || ctaColor}
                      >
                        {ctaText}
                      </CtaLink>
                    </div>
                  )}
                </Body>
              </ProgramSlider>
            )
          )}
        </Slider>
      </CircleArrows>
    </ProgramCarouselContainer>
  </ProgramCarouselWrapper>
);
