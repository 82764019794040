import styled from 'styled-components';
import { color, ColorProps } from 'styled-utils';
import { typography } from '@bbnb/openfit-frontend-shared';
import { Container } from 'components/RichTextWithBackground/RichTextWithBackground.styles';
import { ArrowStyle } from 'components/Pdp';

export const ProgramCarouselWrapper = styled.div<ColorProps>`
  ${color};
  ${Container} {
    padding: 0;
  }
`;
export const ProgramCarouselContainer = styled.div`
  margin: auto;
  max-width: 1136px;
`;
export const ProgramSlider = styled.a<
  ColorProps & { roundedCorners?: boolean }
>`
  ${color};
  display: block;
  text-decoration: none;
  ${({ roundedCorners }) => (roundedCorners ? 'border-radius: 15px' : '')};
  margin-bottom: 60px;
  overflow: hidden;
`;

export const Body = styled.div<ColorProps>`
  ${color};
  height: 210px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 13px 14px 25px;

  p {
    margin: 0;
  }
`;

export const BodyContent = styled.div`
  overflow: hidden;
`;

export const CtaLink = styled.a<ColorProps>`
  ${typography('small')};
  font-weight: ${({ theme }) => theme.fontWeights.black};
  color: ${({ color }) => color};

  ${ArrowStyle};
  :after {
    ${color};
  }
  :hover {
    color: ${({ color }) => color};
    opacity: 0.6;

    :after {
      opacity: 0.6;
      ${color};
    }
  }
`;
