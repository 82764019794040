import { SanitySellPageDevices } from 'graphql-types';
import Devices from 'components/Devices/index';
import { graphql } from 'gatsby';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

interface Props {
  fields: SanitySellPageDevices;
}

export const SanityDevices = ({ fields }: Props) => {
  const theme = useContext(ThemeContext);
  return (
    <Devices
      header="Available on all your favorite devices"
      backgroundColor={fields.backgroundColor?.hex || theme.colors.green800}
      iconColor={fields.svgImageColor?.hex || theme.colors.basic100}
    />
  );
};

export const query = graphql`
  fragment SanitySellPageDevices on SanitySellPageDevices {
    _key
    _type
    __typename
    name
    svgImageColor {
      hex
    }
    backgroundColor {
      hex
    }
  }
`;
