import React from 'react';
import { DeviceIcon } from 'components/Devices/model';

export const GoogleIcon = ({ color }: DeviceIcon) => (
  <svg
    width="57"
    height="66"
    viewBox="0 0 57 66"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.30097 21.3784C3.14656 21.3784 2.16709 21.775 1.36195 22.5681C0.556806 23.3612 0.154114 24.313 0.154114 25.4238V42.4789C0.154114 43.6175 0.556685 44.5816 1.36195 45.3748C2.16709 46.1679 3.14656 46.5646 4.30097 46.5646C5.45453 46.5646 6.42807 46.168 7.22073 45.3748C8.01182 44.5817 8.40845 43.6175 8.40845 42.4789V25.4238C8.40845 24.3128 8.00588 23.3612 7.20062 22.5681C6.39536 21.775 5.4286 21.3784 4.30097 21.3784Z" />
    <path d="M37.6405 6.06799L40.4993 0.872936C40.6869 0.529002 40.6202 0.265263 40.2984 0.0798111C39.9491 -0.0801027 39.6808 0.000928208 39.4933 0.317176L36.5945 5.55376C34.0437 4.44283 31.3459 3.88636 28.501 3.88636C25.6555 3.88636 22.9575 4.44295 20.4077 5.55376L17.5088 0.317176C17.3204 0.000928208 17.052 -0.0793867 16.7036 0.0798111C16.3811 0.266099 16.3144 0.529002 16.5028 0.872936L19.3617 6.06799C16.4628 7.52428 14.154 9.55244 12.4361 12.1569C10.7182 14.7628 9.85895 17.6104 9.85895 20.7049H47.1026C47.1026 17.6111 46.2432 14.7634 44.5255 12.1569C42.8075 9.55244 40.512 7.52428 37.6405 6.06799ZM21.112 13.6243C20.8029 13.9295 20.4336 14.0815 20.0044 14.0815C19.5743 14.0815 19.2126 13.9295 18.9175 13.6243C18.6223 13.3204 18.4748 12.9581 18.4748 12.5339C18.4748 12.1111 18.6223 11.748 18.9175 11.4435C19.2126 11.1396 19.5752 10.9877 20.0044 10.9877C20.4336 10.9877 20.8029 11.1396 21.112 11.4435C21.4205 11.7487 21.5754 12.1111 21.5754 12.5339C21.5746 12.9574 21.4197 13.3204 21.112 13.6243ZM38.083 13.6243C37.7873 13.9295 37.4247 14.0815 36.9962 14.0815C36.566 14.0815 36.1969 13.9295 35.8883 13.6243C35.5793 13.3204 35.425 12.9581 35.425 12.5339C35.425 12.1111 35.5793 11.748 35.8883 11.4435C36.1969 11.1396 36.566 10.9877 36.9962 10.9877C37.4254 10.9877 37.7872 11.1396 38.083 11.4435C38.3784 11.7487 38.5257 12.1111 38.5257 12.5339C38.5257 12.9574 38.3782 13.3204 38.083 13.6243Z" />
    <path d="M10.019 48.5474C10.019 49.7649 10.4482 50.7955 11.3068 51.6413C12.1661 52.487 13.2123 52.9099 14.4474 52.9099H17.4271L17.4679 61.9144C17.4679 63.0515 17.8705 64.0171 18.6756 64.8103C19.4808 65.6035 20.4477 66.0001 21.5746 66.0001C22.7282 66.0001 23.7083 65.6035 24.5136 64.8103C25.3189 64.0171 25.7214 63.0516 25.7214 61.9144V52.9107H31.2777V61.9144C31.2777 63.0515 31.6801 64.0171 32.4854 64.8103C33.2907 65.6035 34.2699 66.0001 35.4244 66.0001C36.5781 66.0001 37.5582 65.6035 38.3635 64.8103C39.1687 64.0171 39.5712 63.0516 39.5712 61.9144V52.9107H42.5911C43.7988 52.9107 44.8316 52.4878 45.6917 51.642C46.5502 50.7963 46.9797 49.7656 46.9797 48.5483V22.1313H10.019V48.5474Z" />
    <path d="M52.6992 21.3784C51.5715 21.3784 50.6054 21.7691 49.8002 22.5484C48.995 23.3291 48.5925 24.2881 48.5925 25.4238V42.4789C48.5925 43.6175 48.9949 44.5816 49.8002 45.3748C50.6054 46.168 51.5722 46.5646 52.6992 46.5646C53.8529 46.5646 54.833 46.168 55.6382 45.3748C56.4435 44.5816 56.846 43.6175 56.846 42.4789V25.4238C56.846 24.288 56.4435 23.3291 55.6382 22.5484C54.833 21.7691 53.8529 21.3784 52.6992 21.3784Z" />
  </svg>
);

export default GoogleIcon;
