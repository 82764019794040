import React from 'react';
import { DeviceIcon } from 'components/Devices/model';

export const AppleTvIcon = ({ color }: DeviceIcon) => (
  <svg
    width="76"
    height="36"
    viewBox="0 0 76 36"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.877 10.3302C16.0884 10.3302 18.3402 8.6562 21.2706 8.6562C26.3142 8.6562 28.2978 12.2562 28.2978 12.2562C28.2978 12.2562 24.417 14.247 24.417 19.0782C24.417 24.5268 29.25 26.4042 29.25 26.4042C29.25 26.4042 25.8714 35.946 21.3066 35.946C19.2114 35.946 17.5806 34.5276 15.372 34.5276C13.122 34.5276 10.8882 35.9982 9.432 35.9982C5.2668 36 0 26.9478 0 19.674C0 12.51 4.4568 8.757 8.6364 8.757C11.3544 8.757 13.464 10.3302 14.877 10.3302ZM16.1388 3.132C18.4968 0.0144 21.7746 0 21.7746 0C21.7746 0 22.2624 2.9322 19.9206 5.7546C17.4186 8.7696 14.5764 8.2746 14.5764 8.2746C14.5764 8.2746 14.0436 5.9058 16.1388 3.132ZM55.3608 8.8092L61.4448 25.3458C61.9596 26.6418 62.4204 27.8622 62.8254 29.007C63.2304 30.1536 63.6174 31.2822 63.9864 32.391H64.0962C64.4652 31.32 64.8612 30.1986 65.286 29.034C65.709 27.8694 66.1806 26.64 66.6954 25.344L72.8334 8.8092H75.6L65.0358 35.442H62.7678L52.596 8.8092H55.3608ZM43.7454 2.9286V8.811H51.1002V10.9188H43.7454V28.287C43.7454 30.0636 44.0298 31.4208 44.6022 32.3658C45.1746 33.309 46.1592 33.7788 47.5614 33.7788C48.2274 33.7788 48.798 33.7428 49.2768 33.669C49.6894 33.6108 50.0965 33.5181 50.4936 33.3918L50.7708 35.3898C49.8852 35.7948 48.7044 36 47.2302 36C46.0134 36 45.009 35.7948 44.217 35.388C43.4488 35.0041 42.7988 34.4194 42.336 33.696C41.8752 32.976 41.553 32.1066 41.3676 31.0896C41.1761 29.9992 41.0833 28.8937 41.0904 27.7866V10.9206H36.7218V8.8092H41.0904V3.924L43.7454 2.9268V2.9286Z"
    />
  </svg>
);
