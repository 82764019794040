import styled from 'styled-components';
import {
  space,
  width,
  height,
  maxWidth,
  fontSize,
  lineHeight,
  LayoutProps,
  MarginProps,
} from 'styled-system';
import { SectionHeader } from 'components/Common/Text';
import { background, flexWrap } from 'styled-utils';

export const Container = styled.div`
  ${background};
`;

export const Wrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-top: 12px;

  ${maxWidth({ maxWidth: ['auto', 'calc(100% - 50px)'] })};
  ${space({ pb: ['10px', '22px'] })}
`;

export const DevicesHeader = styled(SectionHeader)`
  color: ${({ theme }) => theme.colors.basic100};
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  font-weight: 600;
  ${maxWidth({ maxWidth: ['100%'] })}
  ${fontSize({ fontSize: ['14px', '20px'] })}
  ${lineHeight({ lineHeight: ['1', '2'] })}
  ${space({ pb: [0, 0] })};
`;

export const DeviceList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ${space({ my: ['7px', '4px'], mx: 'auto' })}
  ${flexWrap({ flexWrap: ['nowrap'] })}
`;

export const DeviceImage = styled.div<LayoutProps & MarginProps>`
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  padding: 0;

  ${space}
  ${width}
  ${height}

  svg {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
`;
