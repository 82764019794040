import * as React from 'react';
import * as S from './styles';

import { DeviceInterface, DevicePropsInterface } from './model';
import { SellPageContainer } from 'components/Common/Layout';
import devices from 'components/Devices/data';
import AppleIcon from 'components/Devices/assets/AppleIcon';
import { ChromecastIcon } from 'components/Devices/assets/ChromecastIcon';
import { GoogleIcon } from 'components/Devices/assets/GoogleIcon';
import { RokuIcon } from 'components/Devices/assets/RokuIcon';
import { ChromecastSmallIcon } from 'components/Devices/assets/ChromecastSmallIcon';
import { AppleTvIcon } from 'components/Devices/assets/AppleTvIcon';
import { FireTvIcon } from 'components/Devices/assets/FireTvIcon';
import { SamsungTvIcon } from 'components/Devices/assets/SamsungTV';
import { OnlyDesktop, OnlyMobile } from '../Common/Media';
import { ThemeContext } from 'styled-components';

const Device = ({
  device: { name, width, height },
  color,
}: {
  device: DeviceInterface;
  color?: string;
}): React.ReactElement | null => {
  return (
    <S.DeviceImage height={height} width={width}>
      {name === 'apple' && <AppleIcon color={color} />}
      {name === 'chromecast' && (
        <>
          <OnlyDesktop height={height} width={width}>
            <ChromecastIcon color={color} />
          </OnlyDesktop>
          <OnlyMobile height={height} width={width}>
            <ChromecastSmallIcon color={color} />
          </OnlyMobile>
        </>
      )}
      {name === 'google' && <GoogleIcon color={color} />}
      {name === 'roku' && <RokuIcon color={color} />}
      {name === 'firetv' && <FireTvIcon color={color} />}
      {name === 'appletv' && <AppleTvIcon color={color} />}
      {name === 'samsungtv' && <SamsungTvIcon color={color} />}
    </S.DeviceImage>
  );
};

const Devices = ({
  devices,
  header,
  backgroundColor,
  iconColor,
}: DevicePropsInterface): React.ReactElement => {
  const theme = React.useContext(ThemeContext);
  return (
    <S.Container background={backgroundColor || theme.colors.green800}>
      <SellPageContainer>
        <S.Wrapper>
          <S.DevicesHeader>{header}</S.DevicesHeader>
          <S.DeviceList>
            {devices.map(
              (device: DeviceInterface): React.ReactElement => (
                <Device key={device.name} device={device} color={iconColor} />
              )
            )}
          </S.DeviceList>
        </S.Wrapper>
      </SellPageContainer>
    </S.Container>
  );
};

Devices.defaultProps = {
  devices,
};

export default Devices;
