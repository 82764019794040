import React from 'react';
import { DeviceIcon } from 'components/Devices/model';

export const SamsungTvIcon = ({ color }: DeviceIcon) => (
  <svg
    width="95"
    height="36"
    viewBox="0 0 95 36"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.56788 36C4.27813 36 3.1903 35.7594 2.30439 35.2807C1.41847 34.802 0.651185 34.2018 0 33.4849L2.42301 31.6916C2.78647 32.277 3.23573 32.7259 3.77082 33.036C4.3059 33.346 4.89651 33.5023 5.54769 33.5023C6.25188 33.5023 6.78696 33.3163 7.15799 32.9442C7.52901 32.5722 7.71579 32.148 7.71579 31.6767C7.71579 31.3915 7.62997 31.1484 7.46086 30.95C7.29176 30.7516 7.06965 30.5804 6.79706 30.4366C6.52447 30.2927 6.20897 30.1662 5.85057 30.0546C5.49217 29.943 5.12366 29.8314 4.74759 29.7197C4.26551 29.5709 3.78344 29.4097 3.30136 29.2361C2.81928 29.0624 2.38515 28.8293 2.00151 28.5366C1.61787 28.2439 1.30489 27.8843 1.06259 27.4552C0.820291 27.0261 0.701665 26.4878 0.701665 25.8429C0.701665 25.2973 0.817767 24.7863 1.0525 24.315C1.28723 23.8438 1.61534 23.4295 2.03937 23.0748C2.4634 22.7202 2.96314 22.4448 3.54366 22.2464C4.12417 22.048 4.75264 21.9488 5.42907 21.9488C6.00201 21.9488 6.51942 22.0008 6.98131 22.1025C7.4432 22.2042 7.86722 22.3456 8.25087 22.5242C8.63451 22.7028 8.98534 22.9186 9.30589 23.1666C9.62391 23.4171 9.92679 23.6875 10.2145 23.9827L8.02623 25.6544C7.41291 24.7317 6.55476 24.2704 5.44673 24.2704C4.79555 24.2704 4.30085 24.4192 3.96264 24.7168C3.62443 25.0145 3.45532 25.3816 3.45532 25.8181C3.45532 26.091 3.53356 26.3216 3.69005 26.5077C3.84653 26.6937 4.05855 26.8599 4.32609 27.0013C4.59363 27.1451 4.90156 27.2716 5.25491 27.3832C5.60574 27.4949 5.97677 27.6139 6.36798 27.7379C6.85006 27.8868 7.33467 28.0579 7.82432 28.2514C8.31144 28.4448 8.75566 28.6929 9.15193 28.998C9.54819 29.303 9.87126 29.6751 10.1186 30.1166C10.366 30.5581 10.4896 31.1087 10.4896 31.766C10.4896 32.3018 10.3786 32.8227 10.1565 33.3336C9.93436 33.8446 9.61634 34.2935 9.19988 34.6854C8.78343 35.0773 8.26854 35.3948 7.65521 35.6354C7.04441 35.8785 6.34779 36 5.56788 36Z" />
    <path d="M11.7007 24.7491H14.3181V26.2001C14.6816 25.7462 15.1409 25.389 15.6962 25.1261C16.2489 24.8632 16.8774 24.7318 17.5816 24.7318C18.4801 24.7318 19.2399 24.8979 19.8582 25.2328C20.4766 25.5676 20.9562 26.0364 21.2944 26.6416C21.6856 26.0612 22.203 25.5974 22.8466 25.2526C23.4902 24.9054 24.2247 24.7318 25.045 24.7318C26.5821 24.7318 27.7406 25.1435 28.523 25.967C29.3055 26.7905 29.6942 27.9165 29.6942 29.3502V35.9827H26.9986V29.5858C26.9986 28.7847 26.7891 28.1522 26.3726 27.6809C25.9562 27.2121 25.3378 26.9765 24.5175 26.9765C23.8007 26.9765 23.2025 27.2146 22.7204 27.6908C22.2383 28.1671 21.9986 28.8839 21.9986 29.8487V35.9827H19.3231V29.5114C19.3231 28.7227 19.1212 28.105 18.7174 27.6536C18.3136 27.2047 17.7154 26.979 16.9203 26.979C16.1783 26.979 15.5624 27.222 15.0753 27.7107C14.5856 28.1993 14.3433 28.931 14.3433 29.9058V35.9827H11.7007V24.7491Z" />
    <path d="M36.4511 36C35.7595 36 35.0806 35.8736 34.4092 35.6206C33.7378 35.3676 33.1371 35.0005 32.602 34.5193C32.0669 34.0381 31.6379 33.4502 31.3123 32.7533C30.9867 32.0563 30.8226 31.2626 30.8226 30.3746C30.8226 29.4742 30.9816 28.6755 31.3022 27.9786C31.6202 27.2816 32.0442 26.6912 32.5717 26.2101C33.0993 25.7289 33.705 25.3618 34.389 25.1088C35.073 24.8558 35.7721 24.7293 36.489 24.7293C37.2436 24.7293 37.9402 24.8632 38.5788 25.1311C39.2174 25.399 39.7449 25.7661 40.1613 26.2299V24.7491H42.7989V35.9802H40.1613V34.3853C39.7449 34.8864 39.2098 35.2807 38.5586 35.5685C37.91 35.8562 37.2083 36 36.4511 36ZM36.8827 33.6189C37.3774 33.6189 37.8342 33.5346 38.2507 33.3659C38.6671 33.1972 39.0255 32.9641 39.3259 32.6615C39.6263 32.3614 39.8635 32.0116 40.0402 31.6148C40.2169 31.2179 40.3027 30.7913 40.3027 30.3349C40.3027 29.8909 40.2143 29.4693 40.0402 29.0724C39.8635 28.6755 39.6263 28.3308 39.3259 28.0356C39.0255 27.7404 38.6671 27.5098 38.2507 27.3411C37.8342 27.1724 37.3774 27.0881 36.8827 27.0881C36.4006 27.0881 35.9488 27.1724 35.5248 27.3411C35.1008 27.5098 34.7348 27.738 34.4218 28.0257C34.1088 28.3134 33.864 28.6557 33.6899 29.0526C33.5132 29.4494 33.4274 29.876 33.4274 30.3324C33.4274 30.7888 33.5157 31.2179 33.6899 31.6222C33.8665 32.024 34.1088 32.3738 34.4218 32.6689C34.7348 32.9641 35.1033 33.1948 35.5248 33.3634C35.9463 33.5346 36.4006 33.6189 36.8827 33.6189Z" />
    <path d="M44.4014 24.7492H47.0188V26.4185C47.5539 25.3048 48.5307 24.7492 49.9492 24.7492C50.3656 24.7492 50.7392 24.7938 51.0723 24.8831C51.4055 24.9724 51.7008 25.089 51.9608 25.2279L50.9638 27.6859C50.7694 27.5569 50.5499 27.4528 50.3101 27.3684C50.0678 27.2866 49.7599 27.2444 49.3813 27.2444C48.614 27.2444 48.0309 27.4577 47.6321 27.8868C47.2359 28.3159 47.0365 29.0154 47.0365 29.9902V35.9803H44.3989V24.7492H44.4014Z" />
    <path d="M52.4127 24.7491H54.3663V23.0426L57.024 21.2568V24.7516H59.3486V27.0757H57.024V31.2799C57.024 31.7661 57.0493 32.1605 57.1023 32.4606C57.1553 32.7607 57.2613 32.9914 57.4253 33.1526C57.5869 33.3138 57.8216 33.418 58.1295 33.4701C58.4349 33.5222 58.8438 33.547 59.3511 33.547V35.9852H58.9801C58.0942 35.9852 57.3547 35.9083 56.7615 35.7545C56.1684 35.6007 55.6964 35.3428 55.3456 34.9781C54.9947 34.6135 54.7423 34.1323 54.5934 33.5395C54.4445 32.9443 54.3688 32.2001 54.3688 31.3022V27.0782H52.4152V24.7491H52.4127Z" />
    <path d="M68.9574 24.4614H65.1487V21.9662H75.444V24.4614H71.6151V35.9802H68.9574V24.4614Z" />
    <path d="M76.4407 21.9662H79.1943L82.5739 31.9869L85.9333 21.9662H88.687L83.9015 35.9802H81.2261L76.4407 21.9662Z" />
    <path d="M78.5589 10.7276L78.3772 0.41925H81.6861V13.4188H76.9284L73.6245 2.75327H73.5513L73.7356 13.4188H70.4519V0.41925H75.4115L78.4857 10.7276H78.5589Z" />
    <path d="M18.4145 1.63215L16.5846 13.5553H12.9728L15.4489 0.41925H21.3979L23.8613 13.5553H20.2722L18.4877 1.63215H18.4145Z" />
    <path d="M33.995 10.4622L35.6431 0.41925H41.0949L41.3902 13.5553H38.046L37.9601 1.74376L37.8869 1.74625L35.6558 13.5553H32.266L30.0298 1.74376H29.9566L29.8708 13.5553H26.5291L26.8269 0.41925H32.2761L33.9218 10.4622H33.995Z" />
    <path d="M7.34705 9.84704C7.47578 10.1645 7.43287 10.5738 7.37229 10.8218C7.26376 11.2509 6.96341 11.6974 6.06992 11.6974C5.23701 11.6974 4.72717 11.2286 4.72717 10.5118L4.72464 9.23687H1.14313V10.2538C1.14313 13.1831 3.4879 14.0637 5.99925 14.0637C8.41974 14.0637 10.4061 13.2575 10.7241 11.0649C10.8882 9.92889 10.767 9.18479 10.709 8.90699C10.1436 6.15378 5.07295 5.34022 4.69436 3.79744C4.63126 3.52956 4.64388 3.26168 4.67921 3.11038C4.77512 2.68871 5.06538 2.22737 5.90082 2.22737C6.68577 2.22737 7.14261 2.70112 7.14261 3.41546C7.14261 3.65358 7.14261 4.22406 7.14261 4.22406H10.4793V3.30632C10.4793 0.446465 7.86447 0 5.97149 0C3.5939 0 1.65802 0.773873 1.29961 2.91691C1.20118 3.49483 1.18856 4.02067 1.33243 4.68541C1.91042 7.36916 6.66053 8.14799 7.34705 9.84704Z" />
    <path d="M50.8052 9.81971C50.9314 10.1347 50.8936 10.5365 50.8305 10.7846C50.7219 11.2162 50.4216 11.6502 49.5407 11.6502C48.7103 11.6502 48.2106 11.1889 48.2106 10.4795L48.2081 9.21698H44.6568L44.6543 10.2215C44.6543 13.1236 46.9789 13.9942 49.4675 13.9942C51.8602 13.9942 53.8289 13.198 54.1419 11.0276C54.3034 9.90405 54.1848 9.16738 54.1293 8.88958C53.569 6.16614 48.5488 5.36002 48.1778 3.83211C48.1147 3.5692 48.1323 3.3038 48.1626 3.15746C48.2585 2.73827 48.5437 2.28189 49.3716 2.28189C50.1465 2.28189 50.6008 2.75316 50.6008 3.4551C50.6008 3.69569 50.6008 4.25874 50.6008 4.25874H53.9021V3.35092C53.9021 0.518348 51.3125 0.0768433 49.4423 0.0768433C47.0899 0.0768433 45.1667 0.833354 44.8158 2.96151C44.7174 3.53695 44.7023 4.05783 44.8461 4.71264C45.4191 7.36911 50.1212 8.14051 50.8052 9.81971Z" />
    <path d="M62.021 11.5808C62.9524 11.5808 63.2401 10.9607 63.3057 10.6333C63.3335 10.4919 63.3386 10.3009 63.3386 10.1298V0.416656H66.7257V9.82964C66.7282 10.0727 66.703 10.5663 66.6879 10.6978C66.4557 13.1459 64.4794 13.9396 62.0185 13.9396C59.5627 13.9396 57.589 13.1484 57.3593 10.6978C57.3467 10.5663 57.3214 10.0727 57.3265 9.82964V0.416656H60.7061V10.1298C60.7035 10.2984 60.7086 10.4919 60.7389 10.6333C60.8045 10.9607 61.0947 11.5808 62.021 11.5808Z" />
    <path d="M89.909 11.4469C90.8732 11.4469 91.2139 10.8491 91.2745 10.4969C91.2998 10.3481 91.3098 10.1645 91.3048 9.99587V8.09095H89.9368V6.17115H94.6667V9.69574C94.6642 9.94378 94.6617 10.1248 94.6238 10.5639C94.4017 12.9549 92.2866 13.8082 89.9191 13.8082C87.5517 13.8082 85.4391 12.9525 85.2195 10.5639C85.1817 10.1248 85.1741 9.94378 85.1741 9.69574L85.1766 4.16949C85.1766 3.93386 85.2044 3.52212 85.2321 3.30881C85.53 0.845807 87.5517 0.0620117 89.9217 0.0620117C92.2891 0.0620117 94.3689 0.838366 94.6163 3.30881C94.6541 3.71807 94.639 4.16949 94.6415 4.16949V4.60604H91.2745V3.86689C91.2745 3.86689 91.272 3.55684 91.2316 3.3733C91.171 3.0831 90.9161 2.41836 89.8964 2.41836C88.9272 2.41836 88.6395 3.04837 88.5663 3.3733C88.5284 3.53948 88.5108 3.77264 88.5108 3.98843V9.99587C88.5082 10.1621 88.5183 10.3481 88.541 10.4969C88.6041 10.8466 88.9424 11.4469 89.909 11.4469Z" />
  </svg>
);
