import React from 'react';
import { DeviceIcon } from 'components/Devices/model';

export const ChromecastSmallIcon = ({ color }: DeviceIcon) => (
  <svg
    width="40"
    height="36"
    viewBox="0 0 24 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M21.333 0H2.12785C1.56498 0.00484999 1.0265 0.235299 0.62848 0.641683C0.230459 1.04807 0.00475021 1.59785 0 2.17254V5.44064H2.12785V2.17254H21.333V17.4361H13.8764V19.6086H21.333C21.8973 19.6086 22.4386 19.3797 22.8376 18.9722C23.2367 18.5648 23.4608 18.0122 23.4608 17.4361V2.17254C23.4608 1.59635 23.2367 1.04375 22.8376 0.636322C22.4386 0.228892 21.8973 0 21.333 0ZM0 16.3405V19.6086H3.20086C3.20086 18.7418 2.86363 17.9106 2.26335 17.2977C1.66307 16.6848 0.848918 16.3405 0 16.3405ZM0 11.9768V14.168C1.41326 14.168 2.76864 14.7411 3.76797 15.7614C4.76729 16.7818 5.32871 18.1656 5.32871 19.6086H7.47474C7.47474 17.5845 6.68723 15.6434 5.28544 14.2121C3.88365 12.7809 1.98242 11.9768 0 11.9768ZM0 7.63176V9.8043C1.2617 9.80183 2.51146 10.0538 3.67758 10.5456C4.8437 11.0375 5.90325 11.7595 6.7954 12.6704C7.68754 13.5813 8.39481 14.6631 8.87649 15.8538C9.35822 17.0444 9.60499 18.3203 9.60258 19.6086H11.7304C11.7304 16.4321 10.4946 13.3858 8.29468 11.1397C6.09476 8.89361 3.1111 7.63176 0 7.63176Z" />
  </svg>
);
