import React from 'react';
import { DeviceIcon } from 'components/Devices/model';

export const RokuIcon = ({ color }: DeviceIcon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="147"
    height="43"
    fill={color}
    viewBox="0 0 147 43"
  >
    <g clipPath="url(#roku)">
      <path d="M38.053 42.342H25.122l-10.29-14.118h-3.439v14.08H0V0h16.327c9.41 0 17.095 6.343 17.095 14.145 0 4.76-2.944 9.009-7.427 11.61l12.058 16.592v-.005zm-16.03-28.197a7.015 7.015 0 0 0-.529-2.71 7.068 7.068 0 0 0-1.537-2.304 7.144 7.144 0 0 0-2.31-1.545 7.201 7.201 0 0 0-2.732-.552h-3.521V21.18h3.52a7.176 7.176 0 0 0 5.016-2.07 7.039 7.039 0 0 0 2.093-4.964z" />
      <path d="M68.449 25.749a17.223 17.223 0 0 1-2.907 9.656 17.475 17.475 0 0 1-7.836 6.426 17.672 17.672 0 0 1-10.12 1.028 17.568 17.568 0 0 1-8.991-4.717 17.293 17.293 0 0 1-4.825-8.87 17.187 17.187 0 0 1 .968-10.03 17.38 17.38 0 0 1 6.435-7.805 17.634 17.634 0 0 1 9.73-2.945c2.297-.01 4.573.428 6.7 1.29a17.478 17.478 0 0 1 5.688 3.733 17.287 17.287 0 0 1 3.81 5.608 17.15 17.15 0 0 1 1.348 6.626zM50.903 16.68c-3.022 0-5.57 4.058-5.57 9.069 0 5.01 2.548 9.069 5.57 9.069 3.021 0 5.636-4.053 5.636-9.064 0-5.01-2.56-9.074-5.636-9.074zM95.191 9.194l-13.13 13.008V9.145H70.664v33.159h11.399v-13.47l13.733 13.448h14.338L92.697 25.025l14.426-14.259v19.7c0 6.528 3.961 12.512 13.959 12.512 4.735 0 9.086-2.666 11.196-5.07l5.131 4.352h2.428V9.194h-11.394v21.44c-1.28 2.22-3.076 3.618-5.834 3.618-2.758 0-4.093-1.632-4.093-6.915V9.194H95.191zM142.605 10.734c0-.577.232-1.13.644-1.539a2.208 2.208 0 0 1 3.107 0c.412.408.644.962.644 1.539s-.232 1.13-.644 1.539a2.208 2.208 0 0 1-3.107 0 2.166 2.166 0 0 1-.644-1.54zm4.142 0a1.896 1.896 0 0 0-.56-1.376 1.933 1.933 0 0 0-1.385-.566 1.969 1.969 0 0 0-1.381.576 1.92 1.92 0 0 0-.563 1.377 1.896 1.896 0 0 0 .56 1.375 1.932 1.932 0 0 0 1.384.567 1.966 1.966 0 0 0 1.388-.58 1.933 1.933 0 0 0 .557-1.384v.01zm-2.818-1.164h1.011c.494 0 .862.239.862.701a.658.658 0 0 1-.522.675l.599.843h-.522l-.549-.767h-.467v.767h-.412V9.57zm.972 1.088c.292 0 .456-.153.456-.365s-.164-.364-.456-.364h-.549v.729h.549z" />
    </g>
    <defs>
      <clipPath id="roku">
        <path fill="#2b2738" d="M0 0h147v43H0z" />
      </clipPath>
    </defs>
  </svg>
);
