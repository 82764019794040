import React from 'react';
import { SanityImage, SanityProgramCarouselFragment } from 'graphql-types';
import { ProgramCarousel, ProgramCarouselItem } from './ProgramCarousel';
import { getSimplifiedImageData } from 'helpers/SanityMapper';

type Item = NonNullable<NonNullable<SanityProgramCarouselFragment['items']>[0]>;

export const SanityProgramCarousel: React.FC<{
  fields: SanityProgramCarouselFragment;
}> = ({ fields }) => {
  return (
    <ProgramCarousel
      roundedCorners={fields.roundedCorners ?? true}
      backgroundColor={fields.backgroundColor?.hex || ''}
      items={
        fields.items?.map(
          (item: Item): ProgramCarouselItem => ({
            ctaText: item.ctaText,
            ctaLink: item.ctaLink,
            ctaColor: item.ctaColor?.hex,
            ctaArrowColor: item.ctaArrowColor?.hex,
            image: getSimplifiedImageData(item.image as SanityImage),
            backgroundColor: item.backgroundColor?.hex,
            content: item.richText,
          })
        ) || []
      }
      footNote={fields?.richText}
    />
  );
};
