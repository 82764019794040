import * as React from 'react';
import { DeviceIcon } from 'components/Devices/model';

const AppleIcon = ({ color }: DeviceIcon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="54"
    height="54"
    fill={color}
    viewBox="0 0 54 54"
  >
    <path d="M14.727 0C6.601 0 0 6.601 0 14.727v24.546C0 47.399 6.601 54 14.727 54h24.546C47.399 54 54 47.399 54 39.273V14.727C54 6.601 47.399 0 39.273 0H14.727zM10.58 18.495c.859 0 1.496.614 1.496 1.424 0 .796-.637 1.41-1.496 1.41-.872 0-1.51-.614-1.51-1.41 0-.81.638-1.424 1.51-1.424zm12.786.7c4.478 0 7.24 3.126 7.24 8.15 0 5.005-2.734 8.16-7.24 8.16-4.53 0-7.277-3.14-7.277-8.16 0-5.034 2.81-8.15 7.277-8.15zm15.868 0c3.155 0 5.46 1.841 5.61 4.468h-2.355c-.234-1.438-1.476-2.334-3.293-2.334-1.913 0-3.178.91-3.178 2.32 0 1.103.815 1.745 2.852 2.22l1.707.422c3.202.747 4.51 2.037 4.51 4.376 0 2.987-2.305 4.847-6.001 4.847-3.447 0-5.767-1.774-5.935-4.564h2.392c.21 1.477 1.654 2.417 3.73 2.417 1.936 0 3.36-1.002 3.36-2.393 0-1.174-.891-1.889-2.958-2.378l-2.013-.479c-2.81-.662-4.099-2.023-4.099-4.305 0-2.776 2.272-4.617 5.671-4.617zM23.366 21.44c-2.934 0-4.799 2.296-4.799 5.906 0 3.596 1.803 5.911 4.8 5.911 2.957 0 4.774-2.315 4.774-5.91 0-3.61-1.817-5.907-4.775-5.907zm-13.94 1.956h2.31v11.74h-2.31v-11.74z" />
  </svg>
);

export default AppleIcon;
