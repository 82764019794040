import React, { useState, useEffect, FunctionComponent } from 'react';
import styled from 'styled-components';
// need to force file-loader
// since svg sprite loader breaks loader animation
// eslint-disable-next-line import/no-webpack-loader-syntax
import spinner from '!file-loader!./Loader.svg';

const LoadingDiv = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: ${({ theme }) => theme.colors.basic100};
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface SpinnerProps {
  size?: number;
  invert?: boolean;
}
export const LoadingSpinner = styled.div<SpinnerProps>`
  background-image: url(${spinner});
  background-position: center;
  background-repeat: no-repeat;
  background-size: ${({ size }): number => (size ? size * 0.7 : 70)}px;
  height: ${({ size }): number => size || 100}px;
  width: ${({ size }): number => size || 100}px;
  ${({ invert }): string =>
    invert ? 'filter: invert(100%) grayscale(100%)' : ''};
`;

export const Loading: FunctionComponent = () => (
  <LoadingDiv id="of-loading-global" key="of-loading">
    <LoadingSpinner />
  </LoadingDiv>
);

export const DelayedLoading: FunctionComponent = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 500);
    return () => clearTimeout(timeout);
  }, []);

  if (show) {
    return <Loading />;
  }

  return null;
};
